import React from "react"
import { Router } from "@reach/router";

import SEO from "../components/seo"
import PrivateRoute from "../components/PrivateRoute";
import { useAuth0 } from "../react-auth0-spa";
import { Link } from "gatsby";
import { Nav } from "react-bootstrap";

const Home = () => <p>Welcome to the members management page.</p>;
const Profile = () => <p>Profile Page</p>;
const Subscription = () => <p>Subscription Page</p>;
const Login = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  return(
    <div>
      {!isAuthenticated && (
        <button onClick={() => loginWithRedirect({})}>Log in</button>
      )}
    </div>
  )
}

const ManageMembers = () => (
  <>
    <SEO title="Members" />
    <h1>Manage Members </h1>
    <Nav variant="tabs" defaultActiveKey="home">
      <Nav.Item>
        <Nav.Link as={Link} to="/manage-members" eventKey="home">Home</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/manage-members/profile" eventKey="profile">Profile</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/manage-members/subscription" eventKey="subscription">Subscription</Nav.Link>
      </Nav.Item>
    </Nav>
    <Router basepath="/manage-members">
        <Home path="/" />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/subscription" component={Subscription} />
        <Login path="/login" />
      </Router>
  </>
);

export default ManageMembers;
