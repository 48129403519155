import React from "react"
import { navigate } from "gatsby"
import { useAuth0 } from "../react-auth0-spa";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { isAuthenticated } = useAuth0();

  let isLoggedIn = isAuthenticated;
  
  if (!isLoggedIn && location.pathname !== `/manage-members/login`) {
    navigate("/manage-members/login");
    return null;
  }

  return <Component {...rest} />;
}

export default PrivateRoute;